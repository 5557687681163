angular.module("twshared").factory('alertDialog', ['$uibModal', function ($uibModal) {

    var so = {};

    function showAlert(opts) {
        return $uibModal.open({
            animation: false,
            template: require('./confirm.html'),
            controller: 'ConfirmController as conctrl',
            windowClass: "modal fade in",
            size: 'md',
            resolve: {
                def: function () {
                    return opts;
                }
            }
        }).result;
    }

    so.confirmAdvanced = function confirmAdvanced(opts) {
        return showAlert(opts);
    };

    so.confirm = function confirm(message, title) {
        return showAlert({
            title: title,
            message: message
        });
    };

    so.notify = function notify(message, title) {
        return showAlert({
            yesText: "Ok",
            message: message,
            title: title,
            showno: false
        });
    };

    so.warning = function warning(message) {
        return showAlert({
            yesText: "Ok",
            message: message,
            title: "Varning",
            showno: false
        });
    };

    return so;
}]);
