export const RESPONSE_400 = "api_rsp_400";
export const RESPONSE_400_NO_DATA = "api_rsp_400_nodata";
export const RESPONSE_400_JSON_FAIL = "api_rsp_400_jsonfail";
export const RESPONSE_401 = "api_rsp_401";
export const RESPONSE_403 = "api_rsp_403";
export const RESPONSE_404 = "api_rsp_404";
export const RESPONSE_500 = "api_rsp_500";
export const RESPONSE_502 = "api_rsp_502";
export const RESPONSE_502_JSON_FAIL = "api_rsp_502_jsonfail";
export const RESPONSE_ERROR = "api_rsp_error";
