import { RoleData } from './RoleData';

export class ProfileData {
    constructor(json) {
        if (!json) return;

        this.Id = json.Id;
        this.FirstName = json.FirstName;
        this.LastName = json.LastName;
        this.UserName = json.UserName;

        this.IsAccountAdmin = json.IsAccountAdmin;
        this.TromanRoles = new RoleData(json.TromanRoles);
        this.ValmanRoles = new RoleData(json.ValmanRoles);

        if (this.LastName != null) {
            if (this.FirstName != null) {
                this.name = this.FirstName + ' ' + this.LastName;
            } else {
                this.name = this.LastName;
            }
        } else {
            this.name = this.UserName;
        }
    }
}
