import * as _ from 'lodash';
import { ErrorInfo } from './ErrorInfo';

angular.module("twshared").directive("ergoError", ['ergoErrorParser', function (ergoErrorParser) {
    return {
        restrict: "EA",
        template: require('./ergoError.html'),
        scope: {},
        link: function (scope, element, attributes) {
            element.addClass('ergoError');
            scope.error = null;
            scope.$parent.$watch(attributes.ergoError, function (err) {
                if (err == null) {
                    scope.error = null;
                } else if (err instanceof ErrorInfo) {
                    scope.error = err;
                } else {
                    scope.error = ergoErrorParser(err);
                }

                function createText(x) {
                    if (_.isString(x)) {
                        return { text: x };
                    } else if (x.text) {
                        return x;
                    } else {
                        return { text: '' };
                    }
                }

                if (scope.error != null) {
                    scope.messages = scope.error.messages.map(createText);
                } else {
                    scope.messages = null;
                }
            });
        }
    };
}]);
