import { RoleData } from './RoleData';

export class AccountData {
    constructor(json) {
        if (json) {
            this.Id = json.Id;
            this.ConcurrencyStamp = json.ConcurrencyStamp;
            this.IsActive = json.IsActive;
            this.FirstName = json.FirstName;
            this.LastName = json.LastName;
            this.UserName = json.UserName;
            this.Created = json.Created;
            this.IsAccountAdmin = json.IsAccountAdmin;

            this.TromanRoles = new RoleData(json.TromanRoles);
            this.ValmanRoles = new RoleData(json.ValmanRoles);

            if (this.LastName != null) {
                if (this.FirstName != null) {
                    this.name = this.FirstName + ' ' + this.LastName;
                } else {
                    this.name = this.LastName;
                }
            } else {
                this.name = this.UserName;
            }
        } else {
            this.TromanRoles = new RoleData();
            this.ValmanRoles = new RoleData();
        }
    }
}