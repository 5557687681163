import * as _ from 'lodash';

angular.module("twshared").controller('ConfirmController', ['$uibModalInstance', 'def', function ($uibModalInstance, def) {

    var self = this;

    function createText(src) {
        let result = { text: '' };

        if (src != null) {
            if (_.isString(src)) {
                result.text = src;
            } else if (src.text) {
                result.text = src.text;
                result.style = src.iserror ? 'tw-alert-dialog__error' : '';
            }
        }

        return result;
    }

    self.yes = def.yesText || "Ja";
    self.no = def.noText || "Nej";
    self.showno = (def.showno == null) ? true : def.showno;
    self.messages = _.map(_.castArray(def.message), createText);
    self.title = def.title || "Bekräfta";
    if (def.options != null) {
        self.result = _.cloneDeep(def.options);
        self.additional = _.castArray(self.result);
    } else {
        self.result = true;
        self.additional = null;
    }

    self.cancel = function cancel() {
        $uibModalInstance.dismiss("cancelled");
    };

    self.ok = function save() {
        $uibModalInstance.close(self.result);
    };

}]);
