import { Authentication } from '../../auth/Authentication';
import { TromanWebApiBase, ValmanWebApiBase, AuthServerApiBase } from "config";

angular.module("twshared").factory('authHelper', ['$q', function ($q) {

    var authService = {};
    var auth = new Authentication(AuthServerApiBase, $q.when);

    authService.checkScope = function checkScope(isTroman) {
        var root = isTroman ? TromanWebApiBase : ValmanWebApiBase;
        let name = isTroman ? 'Troman' : 'Valman';

        return auth.fetch(root + '/api/system/checkscope')
            .then(rsp => {
                if (rsp.status === 403) {
                    throw new Error(`Ditt konto saknar behörighet till ${name}.`);
                } else if (!rsp.ok) {
                    throw new Error(rsp.status + ":" + rsp.statusText);
                } else {
                    return rsp.json();
                }
            })
            .then(data => {
                if (!(data && data.ScopeValid)) {
                    throw new Error(name + ' är inte tillgängligt för denna kund/användare.');
                }
            });
    };

    return authService;

}]);
