angular.module('twshared').directive('ngModel', [function () {
    return {
        restrict: 'A',
        priority: 0, // Lower than angular ngModel.
        require: ['ngModel', '?^ergoValidationContext'],
        link: function (_scope, element, attributes, controllers) {
            if (angular.isDefined(attributes.ergoNoBind)) return;

            var ngModelCtrl = controllers[0];
            var ergoValidationCtrl = controllers[1];
            if (ngModelCtrl && ergoValidationCtrl) {
                ergoValidationCtrl.addModel(ngModelCtrl);
                element.on('$destroy', function () {
                    ergoValidationCtrl.removeModel();
                });
            }
        }
    };
}]);

angular.module('twshared').directive("ergoValidationContext", ['$log', function ($log) {

    function errorAlways() {
        return true;
    }

    function errorWhenNeeded(ngModelCtrl) {
        return ngModelCtrl.$touched || ngModelCtrl.$dirty || ngModelCtrl.$$parentForm.$submitted;
    }

    return {
        restrict: 'A',
        priority: 1,
        controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
            var ngModelCtrl = null;
            var enableError;
            var cancelWatch = null;
            var warnings = {};
            var warnCount = 0;

            if ($attrs.ergoValidationContext === 'always') {
                enableError = errorAlways;
            } else {
                enableError = errorWhenNeeded;
            }

            this.addModel = function (_ngModelCtrl) {
                if (ngModelCtrl != null) {
                    $log.error('ergoValidationContext, already initialized.');
                    return;
                }
                ngModelCtrl = _ngModelCtrl;

                cancelWatch = $scope.$watch(function () {
                    return ngModelCtrl.$invalid && enableError(ngModelCtrl);
                }, function (val) {
                    $element.toggleClass('has-error', val);
                });
            };

            this.removeModel = function removeModel() {
                if (cancelWatch) {
                    cancelWatch();
                    cancelWatch = null;
                    $element.toggleClass('has-error', false);
                }
            };

            this.toggleWarning = function toggleWarning(name, val) {
                var prev = !!warnings[name];
                var cur = !!val;
                if (prev !== cur) {
                    warnings[name] = cur;
                    warnCount = warnCount + (cur ? 1 : -1);
                    $element.toggleClass('has-warning', warnCount > 0);
                }
            };

            this.hasError = function hasError(name) {
                if (ngModelCtrl == null) return false;
                return (!!ngModelCtrl.$error[name]) && enableError(ngModelCtrl);
            };
        }]
    };
}]);

angular.module('twshared').directive('ergoErrorOn', [function () {
    return {
        restrict: 'A',
        priority: 0,
        require: '?^ergoValidationContext',
        link: function (scope, element, attributes, ergoValidationCtrl) {
            if (ergoValidationCtrl && attributes.ergoErrorOn) {
                scope.$watch(function () {
                    return ergoValidationCtrl.hasError(attributes.ergoErrorOn);
                }, function (val) {
                    element.toggleClass('active', val);
                });
            }
        }
    };
}]);

angular.module('twshared').directive('ergoWarn', [function () {

    var reg = /^\s*([-_a-zA-Z]+)\s+on\s+(.*)$/;

    return {
        restrict: 'A',
        priority: 0,
        require: '?^ergoValidationContext',
        link: function (scope, element, attributes, ergoValidationCtrl) {
            if (!ergoValidationCtrl) return;

            var match = reg.exec(attributes.ergoWarn);
            if (!match) throw "Invalid warn expression: " + attributes.ergoWarn;

            var name = match[1];
            var expr = match[2];

            function setActive(val) {
                ergoValidationCtrl.toggleWarning(name, val);
                element.toggleClass('active', val);
            }

            var cancelWatch = scope.$watch(function () {
                return !!scope.$eval(expr);
            }, setActive);

            element.on('$destroy', function () {
                cancelWatch();
                setActive(false);
            });
        }
    };
}]);

angular.module('twshared').directive('ergoDisconnectModel', [function () {
    return {
        restrict: 'A',
        priority: 0, // After ngModel
        require: 'ngModel',
        compile: function () {
            return ({
                pre: function (_scope, _element, _attributes, modelCtrl) {
                    if (modelCtrl && modelCtrl.$$parentForm) {
                        modelCtrl.$$parentForm.$removeControl(modelCtrl);
                    }
                },
                post: function () { }
            });
        }
    };
}]);
