import { ProfileEndpoints } from './ProfileEndpoints';
import { UsersEndpoints } from './UsersEndpoints';
import { AccountsEndpoints } from './AccountsEndpoints';
import { CustomerEndpoints } from './CustomerEndpoints';

/**
 * @typedef { import('../auth/Authentication').Authentication } Authentication
 */

export class AccountServer {

    /**
     *
     * @param {Authentication} auth
     */
    constructor(auth) {
        this.Profile = new ProfileEndpoints(auth);
        this.Users = new UsersEndpoints(auth);
        this.Accounts = new AccountsEndpoints(auth);
        this.Customer = new CustomerEndpoints(auth);
    }
}
