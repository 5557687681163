import * as _ from 'lodash';
import { AuthError } from './AuthError';
import { createAuthStore } from './AuthStore';

/**
 * @typedef { import('../../login/sso/SSOModels').LoginBySSOSession } LoginBySSOSession
 */

const authStore = createAuthStore('ls.authorizationData');

function getErrorMessage(data) {
    switch (data.type) {
        case "https://www.tromanwebb.se/auth/error/invalid_credentials": return 'Fel e-postadress/lösenord.';
        case "https://www.tromanwebb.se/auth/error/locked_out": return 'Max antal misslyckade inloggningsförsök uppnått. Användarkontot låst för ytterligare inloggningsförsök i 30 min.';
        case "https://www.tromanwebb.se/auth/error/filtered": return 'Inloggning tillåts ej.';
        case "https://www.tromanwebb.se/auth/error/sso_inconsistent_user": return 'Inkonsekvent användarnamn eller e-post.';
        default: return 'Inloggning misslyckades';
    }
}

export class Authentication {
    /**
     * 
     * @param {string} url - URL of login endpoint
     * @param {Function} promiseWrapper
     * @param {Function} loginNeeded
     */
    constructor(url, promiseWrapper, loginNeeded) {
        this._url = url;
        this._pw = promiseWrapper || _.identity;
        this._loginNeeded = loginNeeded || _.noop;

        var authData = authStore.get();
        this.isAuthenticated = !!(authData && authData.token);
    }

    static clearLogin() {
        authStore.remove();
    }

    /**
     * 
     * @param {string} username
     * @param {boolean} isTroman
     */
    initLogin(username, isTroman) {
        const init = {
            method: 'POST',
            body: JSON.stringify({
                UserName: username,
                Scope: isTroman ? 0 : 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let promise = fetch(this._url + '/login/init', init)
            .then(rsp => rsp.json().then(json => ({ rsp, data: json || {} })))
            .catch(err => { throw new AuthError('auth_init_error', 'Internt fel', err); }) 
            .then(({ rsp, data }) => {
                if (rsp.status !== 200) {
                    throw new AuthError('auth_init_failed', "Inloggning misslyckades", rsp);
                }
                return data;
            })
            .catch(err => {
                this.logout();
                return Promise.reject(err);
            });

        return this._pw(promise);
    }

    /**
     * 
     * @param {string} customerId
     * @param {boolean} isTroman
     */
    initSSOAutoLogin(customerId, isTroman) {
        const init = {
            method: 'POST',
            body: JSON.stringify({
                CustomerId: customerId,
                Scope: isTroman ? 0 : 1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let promise = fetch(this._url + '/login/sso/init', init)
            .then(rsp => rsp.json().then(json => ({ rsp, data: json || {} })))
            .catch(err => { throw new AuthError('auth_grantreq_error', 'Internt fel', err); }) // todo: Other error code?
            .then(({ rsp, data }) => {
                if (rsp.status !== 200) {
                    throw new AuthError('auth_grantreq_failed', "Inloggning misslyckades", rsp); // todo: Other error code?
                }
                return data;
            })
            .catch(err => {
                this.logout();
                return Promise.reject(err);
            });

        return this._pw(promise);
    }

    /**
     * 
     * @param {LoginBySSOSession} loginBySSOSession
     */
    loginBySSOSession(loginBySSOSession) {
        const init = {
            method: 'POST',
            body: JSON.stringify(loginBySSOSession),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let promise = fetch(this._url + '/login/sso/session', init)
            .then(rsp => rsp.json().then(json => ({ rsp, data: json || {} })))
            .catch(err => { throw new AuthError('auth_sso_session_error', 'Internt fel', err); })
            .then(({ rsp, data }) => {
                if (rsp.status === 200) {
                    authStore.set({
                        token: data.Token
                    });
                    this.isAuthenticated = true;
                } else if (rsp.status === 400) {
                    throw new AuthError('auth_sso_session_rejected', getErrorMessage(data), data);
                } else {
                    throw new AuthError('auth_sso_session_failed', "Inloggning misslyckades", rsp);
                }
            })
            .catch(err => {
                this.logout();
                return Promise.reject(err);
            });

        return this._pw(promise);
    }

    /**
     * 
     * @param {string} username
     * @param {string} password
     */
    login(username, password) {
        const init = {
            method: 'POST',
            body: JSON.stringify({
                UserName: username,
                Password: password,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let promise = fetch(this._url + '/login', init)
            .then(rsp => rsp.json().then(json => ({ rsp, data: json || {} })))
            .catch(err => { throw new AuthError('auth_grantreq_error', 'Internt fel', err); })
            .then(({ rsp, data }) => {
                if (rsp.status === 200) {
                    authStore.set({
                        token: data.Token
                    });
                    this.isAuthenticated = true;
                } else if (rsp.status === 400) {
                    throw new AuthError('auth_grantreq_rejected', getErrorMessage(data), data);
                } else {
                    throw new AuthError('auth_grantreq_failed', "Inloggning misslyckades", rsp);
                }
            })
            .catch(err => {
                this.logout();
                return Promise.reject(err);
            });

        return this._pw(promise);
    }

    logout() {
        authStore.remove();
        this.isAuthenticated = false;
    }

    /**
     * 
     * @param {RequestInfo} resource
     * @param {?RequestInit} init
     * @returns {Promise<Response>}
     */
    fetch(resource, init) {
        var authData = authStore.get();
        if (authData == null || authData.token == null) {
            this._loginNeeded();
            return Promise.reject(new AuthError('auth_noauthdata', 'Ej inloggad'));
        }

        const mi = _.assign({}, init);
        mi.headers = _.assign({}, mi.headers);
        mi.headers.Authorization = 'Bearer ' + authData.token;

        let result = window.fetch(resource, mi).then(rsp => {
            if (rsp.status === 401) {
                this._loginNeeded();
            }
            return rsp;
        });

        return this._pw(result);
    }
}
