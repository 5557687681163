export class TromanError extends Error {
    code: string;
    extra?: any;
    
    constructor(code: string, msg?: string, extra?: any) {
        super(msg || 'Internt fel 1');
        this.code = code;
        this.extra = extra;
    }
}
