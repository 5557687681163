import { TromanError } from './TromanError';
import { RequestError } from './RequestError';
import {
    RESPONSE_400,
    RESPONSE_400_NO_DATA,
    RESPONSE_400_JSON_FAIL,
    RESPONSE_401,
    RESPONSE_403,
    RESPONSE_404,
    RESPONSE_500,
    RESPONSE_502,
    RESPONSE_502_JSON_FAIL,
    RESPONSE_ERROR
} from './ApiResponseCodes';

/**
 * 
 * @param {Response} rsp
 */
export function checkResponseErrors(rsp) {
    if (rsp.status === 400) {
        return rsp.json().then(data => {
            if (data == null) {
                throw new TromanError(RESPONSE_400_NO_DATA, 'Internt fel 7', rsp);
            } else {
                throw new RequestError(RESPONSE_400, data);
            }
        }, err => {
            throw new TromanError(RESPONSE_400_JSON_FAIL, 'Internt fel 4', err);
        });
    } else if (rsp.status === 401) {
        throw new TromanError(RESPONSE_401);
    } else if (rsp.status === 403) {
        throw new TromanError(RESPONSE_403, 'Du saknar behörighet för denna operation');
    } else if (rsp.status === 404) {
        throw new TromanError(RESPONSE_404, 'Objektet kan inte hittas.');
    } else if (rsp.status === 500) {
        throw new TromanError(RESPONSE_500, 'Internt fel 5');
    } else if (rsp.status === 502) {
        return rsp.json().then(data => {
            throw new TromanError(RESPONSE_502, data ? data.detail : 'Internt fel 8', rsp);
        }, err => {
            throw new TromanError(RESPONSE_502_JSON_FAIL, 'Internt fel 8', err);
        });
    } else if (!rsp.ok) {
        throw new TromanError(RESPONSE_ERROR, 'Internt fel 6', rsp);
    }
    return rsp;
}

// This mimics angularjs $http service
function jsonReplacer(key, value) {
    if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') {
        return undefined;
    } else if (value && value.window === value) {
        return '$WINDOW';
    } else if (value && window.document === value) {
        return '$DOCUMENT';
    } else if (value && value.$evalAsync && value.$watch) {
        return '$SCOPE';
    } else {
        return value;
    }
}

const isUpperInitialRe = /^[A-Z]/;

// Only include uppercase properties.
function initialUpperCaseReplacer(key, value) {
    if (typeof key === 'string' && key.length > 0 && !isUpperInitialRe.test(key)) {
        return undefined;
    } else if (value && value.window === value) {
        return '$WINDOW';
    } else if (value && window.document === value) {
        return '$DOCUMENT';
    } else if (value && value.$evalAsync && value.$watch) {
        return '$SCOPE';
    } else {
        return value;
    }
}


/**
 * 
 * @param {string} method
 * @param {any} body
 * @param {boolean} onlyUppercaseProperties
 */
export function makeRequestInit(method, body, onlyUppercaseProperties) {
    /** @type { RequestInit } */
    let init = {
        method: method,
        headers: {}
    };

    if (body) {
        init.body = JSON.stringify(body, onlyUppercaseProperties ? initialUpperCaseReplacer : jsonReplacer);
        init.headers['Content-Type'] = 'application/json';
    }

    return init;
}
