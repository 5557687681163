import { ErrorInfo } from './ErrorInfo';

ErrorController.$inject = ['$injector', '$uibModalInstance', 'errorinfo'];
function ErrorController($injector, $uibModalInstance, errorinfo) {

    this.error = errorinfo;

    if (errorinfo.action && errorinfo.actiontext) {
        this.actiontext = errorinfo.actiontext;
        this.action = function action() {
            $uibModalInstance.close(true);
            $injector.invoke(errorinfo.action);
        };
    }

    this.cancel = function cancel() {
        $uibModalInstance.dismiss("cancelled");
    };

    this.ok = function save() {
        $uibModalInstance.close(true);
    };

}

angular.module("twshared").factory('errorDialog', ['$uibModal', 'ergoErrorParser', function ($uibModal, ergoErrorParser) {

    var so = {};

    so.show = function show(err) {
        let errorinfo = (err instanceof ErrorInfo) ? err : ergoErrorParser(err);

        return $uibModal.open({
            animation: false,
            template: require('./errorDialog.html'),
            controller: ErrorController,
            controllerAs: 'errctrl',
            windowClass: "modal fade in",
            size: 'md',
            resolve: {
                errorinfo: () => errorinfo
            }
        }).result;
    };

    return so;
}]);
