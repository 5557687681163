import * as _ from 'lodash';
import { AuthError } from './AuthError';

class LocalStore {
    constructor(key) {
        this._key = key;
    }

    get() {
        try {
            let val = window.localStorage.getItem(this._key);
            if (val == null) {
                return null;
            } else {
                return JSON.parse(val);
            }
        } catch (error) {
            return null;
        }
    }

    remove() {
        try {
            window.localStorage.removeItem(this._key);
        } catch (error) {
            //throw new AuthError('auth_ls_remove', 'Användarinformation kunde inte sparas, kontrollera inställningar för webbläsare.', error);
        }
    }

    set(data) {
        try {
            let json = JSON.stringify(data);
            window.localStorage.setItem(this._key, json);
        } catch (error) {
            throw new AuthError('LocalStore_set', 'Användarinformation kunde inte sparas, kontrollera inställningar för webbläsare.', error);
        }
    }
}

export function createAuthStore(key) {
    if (!_.isString(key)) throw new AuthError('createAuthStore_key');

    return new LocalStore(key);
}