import { AccountServiceBase } from "config";
import { checkResponseErrors, makeRequestInit } from '../fetchHelper';

export class AnonServer {
    constructor(promiseWrapper) {
        this._pw = promiseWrapper;
    }

    register(username, token, password) {

        const init = makeRequestInit('POST', {
            UserName: username,
            Token: token,
            Password: password,
        });

        var fp = fetch(AccountServiceBase + '/register', init);

        return this._pw(fp)
            .then(checkResponseErrors)
            .then(() => void (0));
    }

    forgotPassword(username) {

        const init = makeRequestInit('POST', {
            UserName: username,
        });

        var fp = fetch(AccountServiceBase + '/forgotpassword', init);

        return this._pw(fp)
            .then(checkResponseErrors)
            .then(() => void (0));
    }
}
