import * as _ from 'lodash';

export function transitionParamsHash(transition) {
    let persisted = pickPersistedParams(transition.$to(), transition.params());
    return smallhash(persisted);
}

export function smallhash(x) {
    if (!_.isString(x)) return smallhash(JSON.stringify(x));

    if (x.length % 8 !== 0) {
        x = x + ' '.repeat(8 - (x.length % 8));
    }

    var hash = 0, i, chr;
    for (i = 0; i < x.length; i++) {
        chr = x.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    if (hash < 0) {
        hash = ~hash + 1;
    }
    return hash.toString(16);
}

function findParameterDefinition(state, id, ignoreInherited) {
    let param = state.params[id];
    if (!_.isUndefined(param)) {
        if (!ignoreInherited || param.inherit) {
            return param;
        }
    } else if (state.parent && state.parent.name) {
        return findParameterDefinition(state.parent, id, true);
    }
}

/**
 * Filters a param object keeping the params that are defined in config.
 * URL parameters are parsed from the URL and not stored in history.
 * @param {any} state
 * @param {any} params
 */
function pickPersistedParams(state, params) {
    return _.pickBy(params, (_value, key) => {
        let def = findParameterDefinition(state, key);
        // Pick parameters based on location.
        // 0 = "PATH"
        // 1 = "SEARCH"
        // 2 = "CONFIG"
        // PATH and SEARCH should be part of the URL and therefore
        // restored from there.
        return !!(def && def.location === 2);
    });
}

export class HistoryRouterPlugin {

    /**
     * Plugin to UIRouter that enables history save/restore.
     * Requires $locationProvider.html5Mode(true);
     * 
     * @param {any} router
     */
    constructor(router) {
        this.name = "TWHistoryRouterPlugin";
        this.applyHistorySave(router);
        this.applyHistoryRestore(router);
    }

    /**
     * Patches UIRouter by replacing the builtin hook responsible for updating
     * the current URL.
     * UIRouters UrlService can forward state to $location, but does not do so.
     * This patch removes the old hook and adds a different variant that includes
     * params (filtered by pickPersistedParams) as state.
     *  
     * @param {any} router
     */
    applyHistorySave(router) {
        // Original code:
        /*
        var updateUrl = function (transition) {
            var options = transition.options();
            var $state = transition.router.stateService;
            var $urlRouter = transition.router.urlRouter;
            // Dont update the url in these situations:
            // The transition was triggered by a URL sync (options.source === 'url')
            // The user doesn't want the url to update (options.location === false)
            // The destination state, and all parents have no navigable url
            if (options.source !== 'url' && options.location && $state.$current.navigable) {
                var urlOptions = { replace: options.location === 'replace' };
                $urlRouter.push($state.$current.navigable.url, $state.params, urlOptions);
            }
            $urlRouter.update(true);
        };
        var registerUpdateUrl = function (transitionService) {
            return transitionService.onSuccess({}, updateUrl, { priority: 9999 });
        };

        UrlRouter.prototype.push = function (urlMatcher, params, options) {
            var replace = options && !!options.replace;
            this.router.urlService.url(urlMatcher.format(params || {}), replace);
        };
        */

        var updateUrl = function (transition) {
            var options = transition.options();
            var $state = transition.router.stateService;
            var $urlRouter = transition.router.urlRouter;

            if (options.source !== 'url' && options.location && $state.$current.navigable) {
                let replace = options.location === 'replace';
                let urlMatcher = $state.$current.navigable.url;
                let newUrl = urlMatcher.format($state.params || {});
                let persisted = pickPersistedParams($state.$current, $state.params);
                //console.log(`history.push(${newUrl}, replace:${replace}, ${smallhash(persisted)})`);
                router.urlService.url(newUrl, replace, persisted);
            }
            $urlRouter.update(true);
        };

        router.transitionService._deregisterHookFns.updateUrl();
        router.transitionService._deregisterHookFns.updateUrl = router.transitionService.onSuccess({}, updateUrl, { priority: 9999 });
    }

    /**
     * Patches UIRouter to restore state from history.
     * The patch does two things:
     * It triggers a transition when params change and URL doesn't (this is why a simple hook
     * is not enough, UIRouter only triggers on URL change).
     * It augments the params parsed from the URL with the params stored in history.
     * 
     * @param {any} router
     */
    applyHistoryRestore(router) {
        // Original handler:
        // ($state.params and $state.current forwards to router.globals)
        /*
            var handler = function (match) {
                var $state = router.stateService;
                var globals = router.globals;
                if ($state.href(state, match) !== $state.href(globals.current, globals.params)) {
                    $state.transitionTo(state, match, { inherit: true, source: 'url' });
                }
            };
         */

        let $ruleFactory = router.urlService.rules.urlRuleFactory;
        let originalFromState = $ruleFactory.fromState.bind($ruleFactory);
        $ruleFactory.fromState = function (stateOrDecl, router) {
            let result = originalFromState(stateOrDecl, router);

            // The handler is only invoked by UIRouter as part of syncing the current state with $location.url.
            // However, this sync is triggered both by page load, history navigation, and manual transitions.
            result.handler = function (match) {
                var $state = router.stateService;
                var globals = router.globals;

                let stateHref = $state.href(this.state, match);
                let currentHref = $state.href(globals.current, globals.params);

                let historyState = router.locationService.$location.state();
                if (historyState) {
                    // Apply pickPersistedParams to historyState as the state definition may have changed
                    // after history was saved.
                    // TODO, maybe skip restore if the stored state doesn't match the state definition.
                    let historyParams = pickPersistedParams(this.state, historyState);
                    // Prefer URL params as they are "newer".
                    let restoredParams = _.extend({}, historyParams, match);

                    if (stateHref !== currentHref) {
                        // URL is different, go to new state but with params restored from history.
                        // console.log(`${window.location.pathname}> history.restore-url: ${router.locationService.url()} (${smallhash(historyState)} : ${smallhash(historyParams)})`);
                        $state.transitionTo(this.state, restoredParams, { inherit: true, source: 'url' });
                    } else {
                        // URL is the same, compare params and transition if they are different.
                        // This does a deep comparison so complex structures should be avoided in persisted params.
                        // This will be run during all manual transitions.
                        let currentPersistedParams = pickPersistedParams(this.state, globals.params);
                        if (!_.isEqual(currentPersistedParams, historyParams)) {
                            // console.log(`${window.location.pathname}> history.restore-par: ${currentHref}(${smallhash(currentPersistedParams)} -- ${stateHref}(${smallhash(historyParams)}, ID:${globals.twPersistedState === historyState}`);
                            $state.transitionTo(this.state, restoredParams, { inherit: true, source: 'url' });
                        } else {
                            // console.log(`${window.location.pathname}> stay.same: ${currentHref}(${smallhash(currentPersistedParams)}) -- ${stateHref}(${smallhash(historyParams)})`);
                        }
                    }
                } else if (stateHref !== currentHref) {
                    // No history, act like original handler
                    // console.log(`${window.location.pathname}> history.change-url`, match);
                    $state.transitionTo(this.state, match, { inherit: true, source: 'url' });
                }
            };

            return result;
        };
    }
}
