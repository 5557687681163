import * as _ from 'lodash';
import { AccountServiceBase } from "config";
import { checkResponseErrors, makeRequestInit } from '../fetchHelper';
import { UserData } from './UserData';

/**
 * @typedef { import('../../auth/Authentication').Authentication } Authentication
 */

export class UsersEndpoints {
    /**
     * 
     * @param {Authentication} auth
     */
    constructor(auth) {
        this._auth = auth;
    }

    getUsers() {
        return this._auth
            .fetch(AccountServiceBase + '/users', makeRequestInit('GET'))
            .then(checkResponseErrors)
            .then(rsp => rsp.json())
            .then(data => _.map(data, x => new UserData(x)));
    }
}
