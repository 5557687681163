import * as _ from 'lodash';

angular.module("twshared").factory('stateService', ['$state', '$uiRouterGlobals', '$transitions', 'alertDialog', function ($state, $uiRouterGlobals, $transitions, alertDialog) {

    var stateService = {};

    stateService.reload = function reload(reloadState) {
        return $state.transitionTo($state.current, $state.params, {
            reload: _.isUndefined(reloadState) ? true : reloadState,
            inherit: false,
            notify: false,
        });
    };

    stateService.forceReload = function reload(reloadState) {
        return $state.transitionTo($state.current, $state.params, {
            reload: _.isUndefined(reloadState) ? true : reloadState,
            inherit: false,
            notify: false,
            custom: {
                force: true
            }
        });
    };

    stateService.go = function go(to, params, options) {
        return $state.go(to, params, options);
    };

    stateService.getParams = function getParams() {
        return $uiRouterGlobals.transition.params();
    };

    stateService.reloadWithParams = function reloadWithParams(params) {
        return $state.go('.', params, { reload: $uiRouterGlobals.$current });
    };

    stateService.replaceParams = function replaceParams(params) {
        return $state.go('.', params, { location: 'replace' });
    };

    stateService.forceGo = function go(to, params, options) {
        var forceOpts = _.extend({ custom: { force: true } }, options || {});
        return $state.go(to, params, forceOpts);
    };

    function defaultConfirmCallback() {
        return alertDialog.confirm('Det finns osparade ändringar, fortsätt?');
    }

    var unsubscribe = null;
    function doUnsubscribe() {
        if (unsubscribe != null) {
            unsubscribe();
            unsubscribe = null;
        }
    }

    stateService.guard = function guard(callback) {
        doUnsubscribe();

        unsubscribe = $transitions.onBefore({}, function doOnBefore(transition) {
            let options = transition.options();
            let forced = options && options.custom && options.custom.force;

            let confirm = false;
            if (!forced) {
                try {
                    confirm = callback();
                } catch (err) {
                    console.log('Error in stateService.guard', err);
                }
            }

            if (confirm) {
                return defaultConfirmCallback().then(() => {
                    doUnsubscribe();
                    return true;
                });
            } else {
                doUnsubscribe();
            }
        });
    };

    return stateService;

}]);
