angular.module("twshared").filter("ergoDate", ['$filter', function ($filter) {

    var dateFilter = $filter('date');

    return function (val) {
        return dateFilter(val, 'yyyy-MM-dd');
    };
}]);

angular.module("twshared").filter("ergoTime", ['$filter', function ($filter) {

    var dateFilter = $filter('date');

    return function (val) {
        return dateFilter(val, 'HH:mm');
    };
}]);

angular.module("twshared").filter("ergoDateTime", ['$filter', function ($filter) {

    var dateFilter = $filter('date');

    return function (val) {
        return dateFilter(val, 'yyyy-MM-dd HH:mm');
    };
}]);

angular.module("twshared").filter("ergoYesNo", [function () {
    return function (val) {
        if (angular.isUndefined(val)) {
            return '';
        }
        return val ? 'Ja' : 'Nej';
    };
}]);

angular.module("twshared").filter("ergoYesEmpty", [function () {
    return function (val) {
        return val ? 'Ja' : '';
    };
}]);
