angular.module('twshared').directive('ergoSameas', [function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, _element, attributes, ctrl) {
            if (!ctrl) return;
            if (!attributes.ergoSameas) return;

            let compareObject;

            scope.$watch(attributes.ergoSameas, function (val) {
                compareObject = val;
                ctrl.$validate();
            });

            ctrl.$validators.sameas = function (value) {
                if (ctrl.$isEmpty(value))
                    return true;
                return value === compareObject;
            };
        }
    };
}]);
