import * as _ from 'lodash';
import { TromanError } from '../../TromanError';
import { RequestError } from '../../RequestError';
import { AuthError } from '../../auth/AuthError';
import { ErrorInfo } from './ErrorInfo';

function parseError(err) {

    if (err instanceof TromanError) {
        return new ErrorInfo(err.code, [err.message]);
    }

    if (err instanceof AuthError) {
        return new ErrorInfo(err.code, [err.message]);
    }

    if (err instanceof RequestError) {
        if (err.result.errors) {
            let messages = _.flatMap(err.result.errors, x => _.map(x));
            return new ErrorInfo("eparse_data_modelstate", messages);
        }

        if (err.result.detail) {
            return new ErrorInfo("eparse_data_message", [err.result.detail]);
        }

        return new ErrorInfo(err.code, [err.message]);
    }

    if (err instanceof Error) {
        console.log("Runtime error: " + err, err);
        return new ErrorInfo("eparse_runtime_error", ["Internt fel 2"]);
    }

    if (typeof err === 'string') {
        return new ErrorInfo("eparse_string", [err]);
    }

    console.log("Unknown error: " + err, err);
    return new ErrorInfo("eparse_unknown", ['Internt fel 3']);
}

angular.module('twshared').provider('ergoErrorParser', [function ergoErrorParserProvider() {

    var provider = this;
    this.parse = null;
    this.$get = [function ergoErrorParserFactory() {
        return function (err) {
            if (err instanceof ErrorInfo) {
                return err;
            }

            let ei = null;

            if (provider.parse != null) {
                let res = provider.parse(err);
                if (res instanceof ErrorInfo) {
                    ei = res;
                }
            }

            if (ei == null) {
                ei = parseError(err);
            }


            if (ei.source == null) {
                ei.source = err;
            }

            return ei;
        };
    }];
}]);
