angular.module('twshared', ['ui.router', 'ui.bootstrap']);

require('./directives/filters');
require('./directives/ergoValidationContext');
require('./directives/ergoSameas');
require('./directives/ergoValidPassword');

require('./alertDialog/alertDialog');
require('./alertDialog/ConfirmController');

require('./services/stateService');
require('./services/authHelper');

require('./errorHandling/ergoError');
require('./errorHandling/ergoErrorParser');
require('./errorHandling/errorDialog');