import { AccountServiceBase } from "config";
import { checkResponseErrors, makeRequestInit } from '../fetchHelper';
import { AccountData } from './AccountData';

/**
 * @typedef { import('../auth/Authentication').Authentication } Authentication
 */

export class AccountsEndpoints {
    /**
     *
     * @param {Authentication} auth
     */
    constructor(auth) {
        this._auth = auth;
    }

    _dofetch(url, init) {
        return this._auth
            .fetch(AccountServiceBase + url, init)
            .then(checkResponseErrors);
    }

    getAccounts() {
        const init = makeRequestInit('GET');
        return this._dofetch('/accounts', init)
            .then(rsp => rsp.json())
            .then(json => json.map(x => new AccountData(x)));
    }

    /**
     * 
     * @param {string} id
     */
    getAccount(id) {
        const init = makeRequestInit('GET');
        return this._dofetch('/accounts/' + id, init)
            .then(rsp => rsp.json())
            .then(json => new AccountData(json));
    }

    /**
     * 
     * @param {AccountData} account
     */
    addAccount(account) {
        const init = makeRequestInit('POST', account, true);
        return this._dofetch('/accounts', init)
            .then(() => void (0));
    }

    /**
     * 
     * @param {AccountData} account
     */
    updateAccount(account) {
        const init = makeRequestInit('PUT', account, true);
        return this._dofetch('/accounts/' + account.Id, init)
            .then(() => void (0));
    }

    /**
     * 
     * @param {AccountData} account
     */
    archiveAccount(account) {
        const init = makeRequestInit('POST', { Id: account.Id, ConcurrencyStamp: account.ConcurrencyStamp });
        return this._dofetch(`/accounts/${account.Id}/archive`, init)
            .then(() => void (0));
    }

    /**
     * 
     * @param {AccountData} account
     */
    activateAccount(account) {
        const init = makeRequestInit('POST', { Id: account.Id, ConcurrencyStamp: account.ConcurrencyStamp });
        return this._dofetch(`/accounts/${account.Id}/activate`, init)
            .then(() => void (0));
    }

    /**
     * 
     * @param {AccountData} account
     */
    deleteAccount(account) {
        const init = makeRequestInit('POST', { Id: account.Id, ConcurrencyStamp: account.ConcurrencyStamp });
        return this._dofetch(`/accounts/${account.Id}/delete`, init)
            .then(() => void (0));
    }

    /**
     * 
     * @param {AccountData} account
     */
    resetAccount(account) {
        const init = makeRequestInit('POST', { Id: account.Id, ConcurrencyStamp: account.ConcurrencyStamp });
        return this._dofetch(`/accounts/${account.Id}/reset`, init)
            .then(() => void (0));
    }
}
