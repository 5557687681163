angular.module('twshared').directive('ergoValidPassword', [function () {

    const hasNonLetter = /[^a-zA-Z]/;
    const hasLetter = /[a-zA-Z]/;

    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (_scope, _element, _attributes, ctrl) {
            if (!ctrl) return;

            ctrl.$validators.validpassword = function (value) {
                if (ctrl.$isEmpty(value)) {
                    return true;
                }
                return value.length >= 8 && value.length <= 64 && hasLetter.test(value) && hasNonLetter.test(value);
            };
        }
    };
}]);
