export class UserData {
    Id: string;
    FirstName: string;
    LastName: string;
    UserName: string;
    HasTroman: string;
    HasValman: string;
    displayName: string;

    constructor(obj: any) {
        this.Id = obj.Id;
        this.FirstName = obj.FirstName;
        this.LastName = obj.LastName;
        this.UserName = obj.UserName;
        this.HasTroman = obj.HasTroman;
        this.HasValman= obj.HasValman;

        if (this.LastName != null) {
            if (this.FirstName != null) {
                this.displayName = this.FirstName + ' ' + this.LastName;
            } else {
                this.displayName = this.LastName;
            }
        } else {
            this.displayName = this.UserName;
        }
    }
}
