import * as _ from 'lodash';

export const RoleModeEnum = Object.freeze({
    none: 0,
    full: 1,
    readonly: 2,
    detailed: 3,
});

export const DetailVariantEnum = Object.freeze({
    none: 0,
    full: 1,
    limited: 2,
});

function parseRoles(roles) {

    let result = [];

    if (roles.Mode === RoleModeEnum.full) {
        result.push('Administratör');
    } else if (roles.Mode === RoleModeEnum.readonly) {
        result.push('Läsbehörig');
    } else if (roles.Mode === RoleModeEnum.detailed) {

        if (roles.Meeting === DetailVariantEnum.full) {
            result.push('Möten (alla)');
        } else if (roles.Meeting === DetailVariantEnum.limited) {
            result.push('Möten (begränsad)');
        }

        if (roles.Mission === DetailVariantEnum.full) {
            result.push('Uppdrag (alla)');
        } else if (roles.Mission === DetailVariantEnum.limited) {
            result.push('Uppdrag (begränsad)');
        }

        if (roles.Person) {
            result.push('Personer');
        }

        if (roles.Equipment) {
            result.push('Utrustning');
        }

        if (roles.Transaction) {
            result.push('Lönetransaktioner');
        }
    }

    return result;
}

const defaultData = {
    Mode: 0,
    Meeting: 0,
    Mission: 0,
    Person: false,
    Equipment: false,
    Transaction: false,
};

export class RoleData {
    constructor(json) {
        _.extend(this, json || defaultData);
        this.roles = parseRoles(this);
        this.display = this.roles.join(',');
    }
}